<template>
  <div>
    <div class="d-flex align-center mb-2" style="margin-top: -12px">
      <v-tabs v-show="showTabBar" backgroundColor="transparent" v-model="tab">
        <v-tab
          class="pa-0"
          :to="`/sign-off-browse/unitOrientation/unit-field/3/signOff`"
          >單位領域</v-tab
        >
        <v-tab
          class="pa-0"
          :to="`/sign-off-browse/unitOrientation/risk-evaluation/1/signOff`"
          >風險評估</v-tab
        >
        <v-tab
          class="pa-0"
          :to="`/sign-off-browse/unitOrientation/audit-plan/1/signOff`"
          >稽核計畫</v-tab
        >
      </v-tabs>
      <v-btn class="ml-auto" @click="$router.back()" depressed
        >返回待簽核列表</v-btn
      >
    </div>
    <router-view></router-view>
  </div>
</template>
<script>
export default {
  data() {
    return {
      // tab: "/home/to-sign-off",
      tab: "",
    };
  },
  computed: {
    type() {
      return this.$route.params?.type;
    },
    showTabBar() {
      if (this.$route.params?.type === "unitOrientation") return true;
      else return false;
    },
  },
  mounted() {
    console.log(this.$route);
    this.tab = `/sign-off-browse/${this.type}/unit-field/3/signOff`;
  },
};
</script>
